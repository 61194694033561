import "./Assets/css/main.css";
import "./Assets/css/static.css";
import "./Assets/css/popup.css";
// import "./App.css";
// import Home from "./Component/Home";
import Home from "./Home";

function App() {
  return (
    <div className="App">
      <Home />
    </div>
  );
}

export default App;
